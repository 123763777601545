<template>
  <c-box>
    <Tabs
      active-tab="profile"
      :is-program-consultation-basic="isProgramConsultationBasic"
      :tabs="tabs"
    >
      <c-text
        color="neutral.superDarkGray"
        font-size="28px"
        font-weight="700"
      >
        Profil Gizi
      </c-text>

      <c-flex
        margin-top="16px"
        gap="8px"
        align-items="center"
      >
        <c-box
          min-width="20px"
          min-height="20px"
          width="20px"
          height="20px"
        >
          <inline-svg
            :src="require('@/assets/icons/icon-account-circle.svg')"
            fill="#555555"
            width="100%"
            height="100%"
          />
        </c-box>

        <c-text
          color="neutral.darkGray"
          font-size="14px"
          line-height="normal"
        >
          Dibuat oleh {{ getFullnameAndTitle(`${(dataKondisiGiziku?.nutritionist?.firstName || '')} ${(dataKondisiGiziku?.nutritionist?.lastName || '')}`.trim(), dataKondisiGiziku?.nutritionist?.
            education || []) }} - Ahli gizi Dietela
        </c-text>
      </c-flex>

      <c-flex
        margin-top="32px"
        align-items="center"
        justify-content="space-between"
      >
        <c-text
          font-size="20px"
          font-weight="700"
        >
          Kondisi Giziku
        </c-text>
        <BaseButton
          v-if="isRoleNutritionist"
          :left-svg-icon="dataKondisiGiziku?.nutritionalConditions ? require('@/assets/icons/icon-edit-2.svg') : require('@/assets/icons/icon-plus.svg')"
          left-svg-icon-color="white"
          :is-disabled="!isEdited"
          @click="isEdited ? $router.push({
            name: 'nutri.meal-plan.profile.edit',
            params: {
              clientId: $route.params.clientId,
              programId: $route.params.programId,
              month: $route.params.month,
            },
            query: {
              tab: constants.tabs['nutritional-condition']
            },
          }) : ''"
        >
          {{ dataKondisiGiziku?.nutritionalConditions ? 'Edit' : 'Tambah' }}
        </BaseButton>
      </c-flex>
      <BaseDivider />

      <c-box v-if="dataKondisiGiziku?.nutritionalConditions">
        <CardInfoKondisiGiziku
          :type="dataKondisiGiziku?.nutritionalConditions?.calculationType || ''"
          :imt-status="parseInfo.status"
          :imt-value="parseInfo.value"
          :value-indicator="parseIndicatorValue"
          popover-text="Indeks Massa Tubuh (IMT) adalah pengukuran untuk menentukan apakah berat badan Anda sehat berdasarkan tinggi dan berat badan."
        />

        <c-flex
          margin-top="16px"
          gap="16px"
          flex-wrap="wrap"
        >
          <CardInfoOutline
            title="Umur"
            :value="`${dataKondisiGiziku?.nutritionalConditions?.age ?? '-'} tahun`"
          />
          <CardInfoOutline
            title="Jenis kelamin"
            :value="dataKondisiGiziku?.nutritionalConditions?.gender && dataKondisiGiziku?.nutritionalConditions?.gender === 'female'
              ? 'Perempuan'
              : dataKondisiGiziku?.nutritionalConditions?.gender && dataKondisiGiziku?.nutritionalConditions?.gender === 'male'
                ? 'Laki-laki'
                : '-'"
          />
          <CardInfoOutline
            :title="parseInfo.cardInfo3.label"
            :value="parseInfo.cardInfo3.value"
          />
          <CardInfoOutline
            title="BB ideal"
            :value="parseInfo.cardInfo4.value"
          />

          <CardInfoOutline
            v-if="parseInfo.cardInfo5.value"
            title="TB/PB ideal"
            :value="parseInfo.cardInfo5.value"
          />
        </c-flex>

        <c-flex
          width="fit-content"
          max-width="50%"
          margin-top="16px"
        >
          <CardInfoOutlineHighlight
            title="Total Kebutuhan Energi (kkal)"
            :value="`${dataKondisiGiziku?.nutritionalConditions?.totalEnergyNeeded || '-'} kkal`"
          />
        </c-flex>
      </c-box>
      <c-box
        v-else
        margin-top="16px"
      >
        <NoData
          text="Kondisi Giziku belum terisi"
        />
      </c-box>
    
      <!-- Asupan Gizi -->
      <c-flex
        margin-top="32px"
        align-items="center"
        justify-content="space-between"
      >
        <c-text
          font-size="20px"
          font-weight="700"
        >
          Asupan Gizi
        </c-text>
        <BaseButton
          v-if="isRoleNutritionist"
          :left-svg-icon="nutritionalIntake ? require('@/assets/icons/icon-edit-2.svg') : require('@/assets/icons/icon-plus.svg')"
          left-svg-icon-color="white"
          :is-disabled="!isEdited"
          @click="isEdited ? $router.push({
            name: 'nutri.meal-plan.profile.edit',
            params: {
              clientId: $route.params.clientId,
              programId: $route.params.programId,
              month: $route.params.month,
            },
            query: {
              tab: constants.tabs['nutritional-intake']
            },
          }) : ''"
        >
          {{ nutritionalIntake ? 'Edit' : 'Tambah' }}
        </BaseButton>
      </c-flex>
      <BaseDivider />
      <c-box v-if="nutritionalIntake">
        <c-grid
          margin-top="16px"
          width="100%"
          template-columns="repeat(2, 1fr)"
          gap="16px"
        >
          <CardInfoAsupanGizi
            key="energy"
            title="Energi"
            :current="Number(nutritionalIntake?.energyIntakeCurrent) ?? 0"
            :requirement="Number(dataKondisiGiziku?.nutritionalConditions?.totalEnergyNeeded) ?? 0"
            :status="nutritionalIntake?.energyStatus"
            :note="nutritionalIntake?.energyIntakeNote"
            unit="kkal"
          />

          <CardInfoAsupanGizi
            key="protein"
            title="Protein"
            :current="Number(nutritionalIntake?.proteinIntakeCurrent) ?? 0"
            :requirement="Number(nutritionalIntake?.proteinRequirement) ?? 0"
            :status="nutritionalIntake?.proteinStatus"
            :note="nutritionalIntake?.proteinIntakeNote"
            unit="gram"
          />

          <CardInfoAsupanGizi
            key="fat"
            title="Lemak"
            :current="Number(nutritionalIntake?.fatIntakeCurrent) ?? 0"
            :requirement="Number(nutritionalIntake?.fatRequirement) ?? 0"
            :status="nutritionalIntake?.fatStatus"
            :note="nutritionalIntake?.fatIntakenote"
            unit="gram"
          />

          <CardInfoAsupanGizi
            key="carbohydrate"
            title="Karbohidrat"
            :current="Number(nutritionalIntake?.carboIntakeCurrent) ?? 0"
            :requirement="Number(nutritionalIntake?.carbohydrateRequirement) ?? 0"
            :status="nutritionalIntake?.carbohydrateStatus"
            :note="nutritionalIntake?.carboIntakeNote"
            unit="gram"
          />

          <CardInfoAsupanGizi
            key="fiber"
            title="Serat"
            :current="Number(nutritionalIntake?.fiberIntakeCurrent) ?? 0"
            :requirement="Number(nutritionalIntake?.fiberNeedsForAgeCategory) ?? 0"
            :status="nutritionalIntake?.fiberStatus"
            :note="nutritionalIntake?.fiberIntakeNote"
            unit="gram"
          />
        </c-grid>
      </c-box>
      <c-box
        v-else
        margin-top="16px"
      >
        <NoData
          text="Asupan Gizi belum terisi"
        />
      </c-box>

      <!-- Kondisi Tubuh -->
      <c-flex
        margin-top="32px"
        align-items="center"
        justify-content="space-between"
      >
        <c-text
          font-size="20px"
          font-weight="700"
        >
          Kondisi Tubuh
        </c-text>
        <BaseButton
          v-if="isRoleNutritionist"
          :left-svg-icon="bodyConditions.length ? require('@/assets/icons/icon-edit-2.svg') : require('@/assets/icons/icon-plus.svg')"
          left-svg-icon-color="white"
          :is-disabled="!isEdited"
          @click="isEdited ? $router.push({
            name: 'nutri.meal-plan.profile.edit',
            params: {
              clientId: $route.params.clientId,
              programId: $route.params.programId,
              month: $route.params.month,
            },
            query: {
              tab: constants.tabs['body-condition']
            },
          }) : ''"
        >
          {{ bodyConditions.length > 0 ? 'Edit' : 'Tambah' }}
        </BaseButton>
      </c-flex>
      <BaseDivider />
      <c-box v-if="bodyConditions.length > 0">
        <c-flex
          width="100%"
          justify-content="space-between"
          background-color="primary.50"
          color="neutral.black"
          font-size="16px"
          font-weight="500"
          text-align="center"
          border-top-left-radius="12px"
          border-top-right-radius="12px"
          margin-top="16px"
          margin-bottom="8px"
        >
          <c-box
            width="25%"
            text-align="center"
            padding-block="8px"
          >
            Indikator
          </c-box>
          <c-box
            width="25%"
            text-align="center"
            padding-block="8px"
          >
            Nilai Sekarang
          </c-box>
          <c-box
            width="25%"
            text-align="center"
            padding-block="8px"
          >
            Nilai Standard
          </c-box>
          <c-box
            width="25%"
            text-align="center"
            padding-block="8px"
          >
            Status
          </c-box>
        </c-flex>
        <c-grid
          v-for="(item, index) in bodyConditions"
          :key="index"
          w="100%"
          :template-columns="['repeat(4, 1fr)']"
          :gap="['16px']"
          border-bottom-width="1px"
          border-bottom-color="neutral.superLightGray"
          padding-block="4px"
          margin-bottom="8px"
          text-align="center"
        >
          <c-box
            padding-block="8px"
            font-weight="500"
          >
            {{ item?.indicator ?? '-' }}
          </c-box>
          <c-box
            padding-block="8px"
            text-transform="lowercase"
          >
            {{ item?.currentCondition ?? '-' }} {{ item?.currentConditionUnit }}
          </c-box>
          <c-box
            padding-block="8px"
            text-transform="lowercase"
          >
            {{ item?.standardValue ?? '-' }} {{ item?.standardValueUnit }}
          </c-box>
          <c-box
            padding-block="8px"
            font-weight="500"
            :background-color="getBobyConditionStatusColor(item?.conclusion).background"
            :color="getBobyConditionStatusColor(item?.conclusion).color"
          >
            {{ item?.conclusion ?? '-' }}
          </c-box>
        </c-grid>
      </c-box>
      <c-box
        v-else
        margin-top="16px"
      >
        <NoData
          text="Kondisi Tubuh belum terisi"
        />
      </c-box>

      <!-- Tujuan Mealplan -->
      <c-box v-if="!targetAndSuggestions">
        <c-flex
          margin-top="32px"
          align-items="center"
          justify-content="space-between"
        >
          <c-text
            font-size="20px"
            font-weight="700"
          >
            Target dan Saran
          </c-text>
          <BaseButton
            v-if="isRoleNutritionist"
            :left-svg-icon="targetAndSuggestions ? require('@/assets/icons/icon-edit-2.svg') : require('@/assets/icons/icon-plus.svg')"
            left-svg-icon-color="white"
            :is-disabled="!isEdited"
            @click="isEdited ? $router.push({
              name: 'nutri.meal-plan.profile.edit',
              params: {
                clientId: $route.params.clientId,
                programId: $route.params.programId,
                month: $route.params.month,
              },
              query: {
                tab: constants.tabs['target-and-suggestion']
              },
            }) : ''"
          >
            {{ targetAndSuggestions ? 'Edit' : 'Tambah' }}
          </BaseButton>
        </c-flex>
        <BaseDivider />

        <c-box
          margin-top="16px"
        >
          <NoData
            text="Target dan Saran belum terisi"
          />
        </c-box>
      </c-box>

      <c-box v-else>
        <c-text
          margin-top="16px"
          font-size="20px"
          font-weight="700"
        >
          Tujuan Mealplan
        </c-text>

        <c-text
          margin-top="10px"
          color="neutral.superDarkGray"
          font-size="16px"
        >
          {{ targetAndSuggestions.mealPlanGoals }}
        </c-text>

        <c-flex
          gap="16px"
          margin-top="16px"
        >
          <CardInfoThingsThatNeed
            label="Yang perlu dipertahankan"
            :items="targetAndSuggestions?.thingsThatNeedToBeMaintained || []"
          />
          <CardInfoThingsThatNeed
            color="warning"
            label="Yang harus diperbaiki"
            :items="targetAndSuggestions?.thingsThatNeedToBeImproved || []"
          />
        </c-flex>

        <!-- Target -->
        <c-flex
          margin-top="32px"
          align-items="center"
          justify-content="space-between"
        >
          <c-text
            font-size="20px"
            font-weight="700"
          >
            Target
          </c-text>
          <BaseButton
            v-if="isRoleNutritionist"
            :left-svg-icon="require('@/assets/icons/icon-edit-2.svg')"
            left-svg-icon-color="white"
            :is-disabled="!isEdited"
            @click="isEdited ? $router.push({
              name: 'nutri.meal-plan.profile.edit',
              params: {
                clientId: $route.params.clientId,
                programId: $route.params.programId,
                month: $route.params.month,
              },
              query: {
                tab: constants.tabs['target-and-suggestion']
              },
            }) : ''"
          >
            {{ targetAndSuggestions ? 'Edit' : 'Tambah' }}
          </BaseButton>
        </c-flex>
        <BaseDivider />

        <c-box
          as="ol"
          padding-left="24px"
        >
          <c-box
            v-for="(item, index) in targetAndSuggestions?.targetForThisPeriod || []"
            :key="index"
            as="li"
          >
            <c-text
              color="neutral.superDarkGray"
              font-size="16px"
            >
              {{ item }}
            </c-text>
          </c-box>
        </c-box>

        <!-- Saran -->
        <c-flex
          margin-top="32px"
          align-items="center"
          justify-content="space-between"
        >
          <c-text
            font-size="20px"
            font-weight="700"
          >
            Saran - saran
          </c-text>
        </c-flex>
        <BaseDivider />

        <c-box
          as="ol"
          padding-left="24px"
        >
          <c-box
            v-for="(item, index) in targetAndSuggestions?.nutritionAndLifestyleAdvice || []"
            :key="index"
            as="li"
          >
            <c-text
              color="neutral.superDarkGray"
              font-size="16px"
            >
              {{ item }}
            </c-text>
          </c-box>
        </c-box>
      </c-box>

      <!--  xxx -->
      <c-flex
        width="100%"
        margin-top="16px"
        background-color="secondary.50"
        padding-block="20px"
        align-items="center"
        justify-content="center"
      >
        <c-flex
          max-width="330px"
          flex-direction="column"
          align-items="center"
          gap="16px"
        >
          <c-flex
            align-items="center"
            gap="8px"
          >
            <c-text
              font-size="16px"
              color="#111"
              font-weight="500"
            >
              Temukan berbagai variasi golongan makan beserta takaran porsinya
            </c-text>
            <c-image
              :src="require('@/assets/images/image-lightbulb-moment.png')"
              :width="['120px']"
            />
          </c-flex>
          <c-button
            variant="solid"
            variant-color="primary"
            border-radius="30px"
            height="47px"
            font-weight="500"
            @click="showModalDirectGlossary = true"
          >
            Lihat Daftar & Nilai Gizi Makanan
          </c-button>
        </c-flex>
      </c-flex>
    </Tabs>

    <BaseModal
      :is-open="!!showModalDirectGlossary"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <c-image
            height="120px"
            object-fit="cover"
            :src="require('@/assets/images/image-question.svg')"
            alt="image question"
          />
        </c-box>
      </template>
      <template #body>
        <c-box
          justify-content="center"
          padding="30px 24px 20px 24px"
          text-align="center"
        >
          <BaseText
            size-mobile="16px"
            size-desktop="18px"
            color="primary.400"
            margin-bottom="4px"
          >
            Anda yakin untuk dialihkan ke halaman Nilai Gizi?
          </BaseText>
        </c-box>
      </template>
      <template #footer>
        <c-flex
          gap="20px"
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin-bottom="24px"
        >
          <BaseButton
            :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
            left-svg-icon-color="#008C81"
            color="primary"
            rounded="1000px"
            width="100%"
            variant="outlined"
            @click="showModalDirectGlossary = false"
          >
            Batal
          </BaseButton>
          <BaseButton
            :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
            right-svg-icon-color="white"
            color="primary"
            rounded="1000px"
            width="100%"
            @click="$router.push({
              name: isRoleNutritionist ? 'client.glossaries' : 'admin.glossary',
            })"
          >
            Lanjutkan
          </BaseButton>
        </c-flex>
      </template>
    </BaseModal>
  </c-box>
</template>

<script>
import Tabs from '@/components/meal-plan/_widgets/tabs.vue'
import CardInfoKondisiGiziku from '@/components/meal-plan/_widgets/card-info-kondisi-giziku.vue'
import BaseDivider from '@/components/elements/base-divider.vue'
import BaseModal from '@/components/elements/base-modal.vue'
import BaseText from '@/components/elements/base-text.vue'
import BaseButton from '@/components/elements/base-button.vue'
import CardInfoOutline
  from '@/components/meal-plan/_widgets/card-info-outline.vue'
import CardInfoOutlineHighlight
  from '@/components/meal-plan/_widgets/card-info-outline-highlight.vue'
import { getFullnameAndTitle } from '@/utils/specific-case-utils'
import NoData from '@/components/no-data.vue'
import {
  tabs,
} from '@/constants/meal-plans'
import CardInfoThingsThatNeed
  from '@/components/meal-plan/_widgets/card-info-things-that-need.vue'
import { CBox, CFlex, CGrid, CText } from '@chakra-ui/vue'
import CardInfoAsupanGizi
  from '@/components/meal-plan/_widgets/card-info-asupan-gizi.vue'

export default {
  name: 'MealPlanProfileContainer',
  components: {
    CardInfoAsupanGizi,
    CardInfoThingsThatNeed,
    NoData,
    CardInfoOutlineHighlight,
    CardInfoOutline,
    BaseModal,
    BaseText,
    BaseButton,
    BaseDivider,
    CardInfoKondisiGiziku,
    Tabs,
    CBox,
    CText,
    CFlex,
    CGrid,
  },
  props: {
    isEdited: {
      type: Boolean,
      default: true,
    },
    isProgramConsultationBasic: {
      type: Boolean,
      default: false,
    },
    dataKondisiGiziku: {
      type: [Object, null],
      default: null,
    },
    nutritionalIntake: {
      type: [Object, null],
      default: null,
    },
    bodyConditions: {
      type: Array,
      default: () => [],
    },
    targetAndSuggestions: {
      type: [Object, null],
      default: null,
    },
    tabs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showModalDirectGlossary: false,
    }
  },
  computed: {
    constants() {
      return {
        tabs,
      }
    },
    parseInfo() {
      switch (this.dataKondisiGiziku?.nutritionalConditions?.calculationType) {
        case 'adult':
          return {
            status: this.dataKondisiGiziku?.nutritionalConditions?.imt?.status || '-',
            value: this.dataKondisiGiziku?.nutritionalConditions?.imt?.value || 0,
            cardInfo3: {
              label: 'Berat badan/Tinggi badan',
              value: `${this.dataKondisiGiziku?.nutritionalConditions?.weight || '-'}kg / ${this.dataKondisiGiziku?.nutritionalConditions?.height || '-'}cm`,
            },
            cardInfo4: {
              value: this.dataKondisiGiziku?.nutritionalConditions?.idealWeightRange || '-',
            },
            cardInfo5: {
              value: null,
            },
          }
        default:
          return {
            status: this.dataKondisiGiziku?.nutritionalConditions?.nutritionalStatusConclusion || '-',
            value: this.dataKondisiGiziku?.nutritionalConditions?.zScoreConclusion || 0,
            cardInfo3: {
              label: 'Berat badan/Tinggi atau Panjang badan',
              value: `${this.dataKondisiGiziku?.nutritionalConditions?.weight || '-'}kg / ${this.dataKondisiGiziku?.nutritionalConditions?.height || '-'}cm`,
            },
            cardInfo4: {
              value: `${this.dataKondisiGiziku?.nutritionalConditions?.childIdealWeight || '-'} kg`,
            },
            cardInfo5: {
              value: `${this.dataKondisiGiziku?.nutritionalConditions?.childIdealHeight || '-'} cm`,
            },
          }
      }
    },
    parseIndicatorValue() {
      switch (this.dataKondisiGiziku?.nutritionalConditions?.calculationType) {
        case 'adult':
          switch (this.dataKondisiGiziku?.nutritionalConditions?.imt?.status) {
            case 'Obesitas 2':
              return 100
            case 'Obesitas 1':
              return 90
            case 'Preobesitas':
              return 75
            case 'Normal':
              return 50
            case 'Berat Badan Kurang':
            default:
              return 0
          }
        default:
          switch (this.dataKondisiGiziku?.nutritionalConditions?.nutritionalStatusConclusion) {
            case 'Obesitas (obese)':
              return 100
            case 'Gizi lebih (overweight)':
              return 90
            case 'Berisiko Gizi lebih (possible risk of overweight)':
              return 75
            case 'Gizi baik (normal)':
              return 50
            case 'Gizi kurang (wasted)':
              return 25
            case 'Gizi buruk (severely wasted)':
            default:
              return 0
          }
      }
    },
  },
  methods: {
    getFullnameAndTitle,
    getBobyConditionStatusColor(status) {
      switch(status) {
        case 'Berlebih':
          return {
            background: 'danger.50',
            color: 'danger.400',
          }
        case 'Kurang':
          return {
            background: 'warning.50',
            color: 'warning.400',
          }
        case 'Cukup':
          return {
            background: 'success.50',
            color: 'success.400',
          }
        case 'Normal':
          return {
            background: 'primary.50',
            color: 'primary.400',
          }
        case 'Tidak ada data':
          return {
            background: 'neutral.lightGray',
            color: 'neutral.black',
          }
        default:
          return {
            background: 'neutral.superLightGray',
            color: 'neutral.black',
          }
      }
    },
  },
}
</script>
